import { registerHandler } from 'event-bus';
import { publish } from 'bv-services/internal-event-bus';
import { accountId } from 'bv-helpers/session';
import { presentOffer } from './api';

import { FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS, updateEnhancedOutcomeOffer } from './duck';

const { store } = window.reduxState;

const updateOffer = (err, { body: offer }) => {
  store.dispatch(updateEnhancedOutcomeOffer(presentOffer(offer)));
  publish('OfferOptIn', presentOffer(offer));
};

const enhancedOffersMiddleware = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS:
      registerHandler(`/offers/customer_optins/${accountId()}`, updateOffer);
      break;
    default:
      break;
  }
};

export default enhancedOffersMiddleware;
