import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addMiddleware } from 'redux-dynamic-middlewares';
import { fetchEnhancedOutcomeOffers } from './api';

import reducer, { fetchEnhancedOutcomeOffersSuccess } from './duck';
import enhancedOffersMiddleware from './middleware';

const { addReducers } = window.reduxState;

addReducers({ enhanedOutcomeOffers: reducer });
addMiddleware(enhancedOffersMiddleware);

const EnhancedOutcomeOffers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchEnhancedOutcomeOffers().then((resp) => {
      dispatch(fetchEnhancedOutcomeOffersSuccess(resp));
    });
  }, []);

  return null;
};

export default EnhancedOutcomeOffers;
