import { mapObject } from 'underscore';

export const FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS = 'enhancedOffers/FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS';
export const UPDATE_ENHANCED_OUTCOME_OFFER = 'enhancedOffers/UPDATE_ENHANCED_OUTCOME_OFFER';

export const fetchEnhancedOutcomeOffersSuccess = (offers) => ({
  type: FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS,
  offers,
});

export const updateEnhancedOutcomeOffer = (offer) => ({
  type: UPDATE_ENHANCED_OUTCOME_OFFER,
  offer,
});

const initialState = {
  offers: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case FETCH_ENHANCED_OUTCOME_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.offers,
      };

    case UPDATE_ENHANCED_OUTCOME_OFFER:
      return {
        ...state,
        offers: state.offers.map((offer) => (
          (action.offer.cpbtId !== offer.id) ? offer : {
            ...offer,
            enhancedOutcomes: mapObject(offer.enhancedOutcomes, (outcome, id) => (
              outcome.outcomeId !== action.offer.enhancedOutcomes[id].outcomeId ? outcome
                : { ...outcome, ...action.offer.enhancedOutcomes[id] }
            )),
          }
        )),
      };

    default:
      return state;
  }
};
