import { indexBy } from 'underscore';
import { getJSON } from 'bv-fetch';

export const presentOffer = (offer) => ({
  ...offer,
  enhancedOutcomes: indexBy(offer.enhancedOutcomes, 'outcomeId'),
});

export const fetchEnhancedOutcomeOffers = () => getJSON('/bv_api/enhanced_outcome_offers')
  .then(({ offers }) => offers.map(presentOffer));
